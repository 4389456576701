<template>
  <v-container fluid>
    <v-row class="d-flex justify-center">
      <v-col cols="12" md="4" class="#faf2df" style="background-color: #f3eee8">
        <!-- BasicData component added here... -->
        <BasicData
          class="mt-md-16"
          ref="basicData"
          @downloadClicked="downloadPDF"
        />
        <!-- End of BasicData -->
      </v-col>

      <!-- section for uploaded invoice image starts here  also not shown in mobile -->
      <v-col cols="12" md="8">
        <v-layout class="d-flex flex-column">
          <div class="d-flex pr-md-5" style="align-items: center; width: 100%">
            <v-breadcrumbs :items="breadcrumbs" class="px-md-2">
              <template v-slot:divider>
                <v-icon class="px-0">mdi-chevron-right</v-icon>
              </template>
            </v-breadcrumbs>
            <v-spacer></v-spacer>
            <v-btn
              @click="$router.go(-1)"
              text
              rounded
              large
              class="mt-10"
              v-if="$vuetify.breakpoint.mdAndUp"
            >
              <v-icon left>mdi-chevron-left</v-icon> Back
            </v-btn>
            <attach-document
              :itemHypnID="invoiceHypnID"
              dispatchURI="invoices"
              entityType="invoice"
            >
              <v-btn
                class="mt-10"
                text
                rounded
                large
                v-if="$vuetify.breakpoint.mdAndUp"
              >
                <v-icon left>mdi-view-grid-outline</v-icon> Tools
              </v-btn>
            </attach-document>
          </div>
          <div class="d-flex pr-md-5" style="align-items: center; width: 100%">
            <v-container>
              <v-card
                elevation="3"
                min-height="100vh"
                color="white"
                min-width="100%"
                max-width="1000px"
                v-if="singleInvoice.invoicetype == 'vendor'"
              >
                <v-skeleton-loader
                  width="100%"
                  height="100%"
                  type="image,table"
                  :loading="loading"
                >
                  <v-img
                    class="mx-auto align-center"
                    max-width="90%"
                    max-height="100%"
                    contain
                    :src="invoiceImage"
                  >
                  </v-img>
                </v-skeleton-loader>
              </v-card>
              <v-card
                background-color="#ffffff"
                elevation="3"
                style="width: 100%; min-height: 700px"
                v-if="singleInvoice.invoicetype == 'customer'"
              >
                <v-skeleton-loader
                  width="100%"
                  height="80vh"
                  type="image,table"
                  :loading="loading"
                >
                  <InvoiceTemplate
                    ref="customerInvoice"
                    :company_from="singleInvoice.organization.orgname"
                    :address_from="singleInvoice.organization.address"
                    :address_to="
                      singleInvoice.customer.address
                        ? singleInvoice.customer.address.address
                        : 'customer address'
                    "
                    :invoicenumber="singleInvoice.invoicenumber"
                    :company_to="companydetails"
                    :total="singleInvoice.total"
                    :subTotal="singleInvoice.subtotal"
                    :lines="singleInvoice.lines"
                    :city_from="singleInvoice.organization.city || ''"
                    :city_to="
                      singleInvoice.customer.address
                        ? singleInvoice.customer.address.city
                        : ''
                    "
                    :country_from="singleInvoice.organization.country || ''"
                    :country_to="
                      singleInvoice.customer.address
                        ? singleInvoice.customer.address.country
                        : ''
                    "
                    :zip_to="
                      singleInvoice.customer.address
                        ? singleInvoice.customer.address.postcoade
                        : ''
                    "
                    :zip_from="singleInvoice.organization.postcode || ''"
                    :organizationLogo="singleInvoice.organization.logo.url"
                    :dueDate="singleInvoice.due_date || ''"
                    :date="singleInvoice.created_at | moment('DD-MM-YYYY')"
                    :addon="singleInvoice.addon ? true : false"
                    :se="singleInvoice.se"
                    :gr="singleInvoice.gr"
                    :currencySym="
                      singleInvoice.currency == 'NGN'
                        ? '₦'
                        : singleInvoice.currency == 'USD'
                        ? '$'
                        : singleInvoice.currency == 'EUR'
                        ? '€'
                        : '£'
                    "
                    :bankName="
                      customerBankDetails ? customerBankDetails.bankname : ''
                    "
                    :bankNumber="
                      customerBankDetails
                        ? customerBankDetails.accountnumber
                        : ''
                    "
                    :bankAccountName="
                      customerBankDetails ? customerBankDetails.accountname : ''
                    "
                    :bankSortCode="
                      customerBankDetails ? customerBankDetails.bankcode : ''
                    "
                    :tax="singleInvoice.vat == '1' ? tax : '0.00'"
                  />
                </v-skeleton-loader>
              </v-card>
            </v-container>
          </div>
        </v-layout>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BasicData from "../../includes/BasicData.vue";
import { mapState, mapGetters } from "vuex";
import InvoiceTemplate from "./components/invoiceTemplate.vue";
import AttachDocument from "@/components/attachDocument.vue";

// import currencyFilter from "@/mixins/currencyFilter";

export default {
  name: "Invoice",
  data() {
    return {
      isAnException: false,
      Exceptions: [],
      isDisabled: false,
      invoiceHypnID: "",
      loading: true,
      breadcrumbs: [
        {
          text: "Inbox",
          to: "/inbox",
          disabled: true,
        },
        {
          text: "Invoice",
          to: "#",
          disabled: true,
        },
        {
          text: "...",
          disabled: false,
        },
      ],
    };
  },
  // mixins: [currencyFilter],

  methods: {
    async getInvoiceData() {
      try {
        const { data } = await this.$store.dispatch(
          "invoices/getInvoiceById",
          this.$route.params.id
        );

        this.invoiceHypnID = data.hypn_id;

        if (data.invoicetype === "customer") {
          // get the customer bank details
          await this.$store.dispatch("contacts/getCustomerBankDetails", {
            organizationId: this.organizationId,
            customerId: data.customer.id,
          });
        }
        this.breadcrumbs[2].text = data.invoicenumber || "...";
      } catch (error) {
        console.log(JSON.stringify(error, null, 2));
      }
    },
    reviewFields() {
      this.$refs.basicData.changeFieldStatus();
    },

    downloadPDF() {
      this.$refs.customerInvoice.downloadPDF();
    },
  },
  async mounted() {
    this.isAnException = this.$route.query.exception || false;
    await this.getInvoiceData();
  },
  created() {
    //make skeleton loader stop
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 5000);
  },
  components: {
    BasicData,
    InvoiceTemplate,
    AttachDocument,
  },

  computed: {
    ...mapGetters({
      user: "auth/user",
      token: "auth/token",
      organizationId: "auth/organizationId",
      invoiceArrayStatus: "invoices/checkInvoiceArray",
      totalInvoice: "invoices/numOfInvoices",
      singleInvoice: "invoices/getSingleInvoice",
      customerBankDetails: "contacts/getCustomerBankDetails",
    }),
    ...mapState({
      organization: "organization",
      formCards: "formBuilder",
    }),

    // calculate tax amount of 7.5 % of singleInvoice.subtotal
    tax() {
      return this.singleInvoice.subtotal * 0.075;
    },

    NumOfExceptions() {
      return Object.keys(this.singleInvoice.exception_fields).length;
    },
    exceptionTags() {
      const arrayValues = Object.values(this.singleInvoice.exception_fields);
      return arrayValues.map((item) => item.replaceAll("_", " "));
    },

    invoiceImage() {
      const value = this.singleInvoice.invoiceimage;
      return "data:image/png;base64," + value;
    },

    // get the company details
    companydetails() {
      // if (this.singleInvoice.customer?.company) {
      //   return this.singleInvoice.customer.company.company_name;
      // } else {
      //   return this.singleInvoice.customer.email;
      // }
      return this.singleInvoice.customer.email;
    },

    configSymbol() {
      return this.singleInvoice.currency ? this.singleInvoice.currency : "";
    },
  },
};
</script>

<style scoped>
.v-divider--inset:not(.v-divider--vertical) {
  margin-left: -20px !important;
}

.v-application--is-ltr .v-divider--inset:not(.v-divider--vertical) {
  margin-left: 100px !important;
}

.v-text-field {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  background-color: transparent;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding-top: 0px;
  margin-bottom: 2px;
  margin-top: 8px;
}

.sub__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: rgba(25, 40, 61, 0.8);
}

.total__amount {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  line-height: 39px;
  color: #455160;
}

.v-breadcrumbs {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  flex: 0 1 auto;
  list-style-type: none;
  margin-top: 16px;
  padding: 18px 8px;
  font-size: medium !important;
}

.v-breadcrumbs li {
  align-items: center !important;
  display: inline-flex !important;
  font-size: medium !important;
  font-weight: 600 !important;
}

/* media queries for tablets */
@media only screen and (max-width: 768px) {
  .v-breadcrumbs {
    padding: 18px 20px;
  }
}

/* media queries for desktop */
@media only screen and (min-width: 768px) {
  .v-breadcrumbs {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    flex: 0 1 auto;
    list-style-type: none;
    margin-top: 42px;
    padding: 18px 70px;
    font-size: large !important;
  }
}

.v-application .elevation-3 {
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 3%), 0px 6px 10px 0px rgb(0 0 0 / 3%),
    0px 1px 18px 0px rgb(0 0 0 / 3%) !important;
}
</style>
