<template>
  <div>
    <div>
      <v-form ref="form">
        <div>
          <!-- title for mobile and desktop screen starts -->
          <div
            class="px-1 py-6 mx-auto align-center"
            :style="{
              display: `${$vuetify.breakpoint.mdAndDown ? 'flex' : 'block'}`,
            }"
          >
            <p class="pt-8 pt-md-0 px-6 mb-md-2 sub__title">Total amount</p>
            <p
              class="pt-5 pt-md-0 px-6 total__amount"
              :class="{
                'text-h4':
                  $vuetify.breakpoint.mdAndUp && singleInvoice.total < 1000000,
                amount_text_size:
                  $vuetify.breakpoint.mdAndUp && singleInvoice.total >= 1000000,
                'text-h6': $vuetify.breakpoint.smAndDown,
              }"
              v-if="changeState"
            >
              {{ singleInvoice.total | currency(configSymbol) }}
            </p>

            <v-text-field
              class="ml-md-4"
              ref="invoicetotal"
              v-if="!changeState"
              v-model="payableData.total"
              :background-color="backgroundColor"
              :rules="rules.amount"
              min="0"
              solo
              :autofocus="true"
              flat
              dense
              hide-details="auto"
            >
            </v-text-field>
          </div>
          <!-- title for mobile and desktop screen ends -->

          <!-- Exception summary card starts here -->
          <div v-if="isAnException" class="px-6">
            <v-card
              width="100%"
              min-height="128"
              flat
              elevation="0"
              style="
                background: #ffffff;
                border: 1px solid #ff6a6a;
                box-sizing: border-box;
                border-radius: 4px;
              "
            >
              <div>
                <div
                  class="px-4 py-2 d-flex justify-space-between"
                  style="border: 1px solid rgba(212, 216, 223, 0.377431)"
                >
                  <span class="error--text font-weight-bold">Exception(s)</span>
                  <v-avatar color="#FF6A6A" size="22">
                    <span class="white--text text-caption">{{
                      NumOfExceptions
                    }}</span>
                  </v-avatar>
                </div>

                <div class="d-flex flex-wrap py-8 px-4">
                  <v-chip-group column>
                    <v-chip
                      v-for="tag in exceptionTags"
                      :key="tag"
                      class="px-2 my-1 mx-1"
                      small
                      color="#FFEBEB"
                      text-color="#B33900"
                    >
                      <span class="px-1">{{ tag }}</span>
                    </v-chip>
                  </v-chip-group>
                </div>
              </div>
            </v-card>

            <div class="d-flex justify-end mt-4">
              <v-btn
                text
                class="text-capitalize mr-2"
                rounded
                style="background-color: #ffffff"
              >
                <img
                  :src="require('@/assets/pbot_icons/pen_flat.svg')"
                  class="navIcon pr-2"
                />
                <span class="text-caption text-capitalize">Notify Vendor</span>
              </v-btn>
              <v-btn
                @click="reviewFields"
                text
                class="text-capitalize"
                rounded
                style="background-color: #ffffff"
              >
                <img
                  :src="require('@/assets/pbot_icons/pen_flat.svg')"
                  class="navIcon pr-2"
                />
                <span class="text-caption text-capitalize">Review</span>
              </v-btn>
            </div>
          </div>
          <!-- Exception summary card starts here -->
          <v-row class="px-4 py-0 mx-sm-0">
            <v-col cols="4" class="pl-4 py-1 ma-0 d-flex align-center">
              <div class="">
                <span class="primary--text text-body-2 font-weight-bold"
                  >Invoice no.</span
                >
              </div>
            </v-col>
            <v-col cols="8" class="py-1 ma-0">
              <v-skeleton-loader
                width="100%"
                type="text"
                :loading="loadingdata"
              >
                <v-text-field
                  ref="invoicenumber"
                  :rules="rules.required"
                  v-model="payableData.invoicenumber"
                  hide-details="auto"
                  :disabled="changeState"
                  :background-color="backgroundColor"
                  solo
                  flat
                  dense
                  :class="!changeState ? changeState : ''"
                  reverse
                  class="ma-0 pa-0 input__field_style"
                  placeholder="Enter Invoice"
                >
                </v-text-field>
              </v-skeleton-loader>
            </v-col>
            <hr class="hr-line" />
          </v-row>
          <v-row class="px-4 py-0 mx-sm-0">
            <v-col cols="4" class="pl-4 py-1 ma-0 d-flex align-center">
              <div class="">
                <span class="primary--text text-body-2 font-weight-bold"
                  >Date</span
                >
              </div>
            </v-col>
            <v-col cols="8" class="py-1 ma-0">
              <v-skeleton-loader
                width="100%"
                type="text"
                :loading="loadingdata"
              >
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="payableData.date"
                      hide-details="auto"
                      :disabled="changeState"
                      :background-color="backgroundColor"
                      solo
                      flat
                      dense
                      v-bind="attrs"
                      v-on="on"
                      :class="[!changeState ? changeState : '']"
                      reverse
                      class="input__field_style"
                      placeholder="Enter Date"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="payableData.date"
                    @input="menu = false"
                    no-title
                    scrollable
                    :min="minDate"
                  >
                  </v-date-picker>
                </v-menu>
              </v-skeleton-loader>
            </v-col>

            <hr class="hr-line" />
          </v-row>
          <v-row class="px-4 py-0 mx-sm-0">
            <v-col cols="4" class="pl-4 py-1 ma-0 d-flex align-center">
              <div class="">
                <span class="primary--text text-body-2 font-weight-bold"
                  >VAT</span
                >
              </div>
            </v-col>
            <v-col cols="8" class="py-1 ma-0">
              <v-skeleton-loader
                width="100%"
                type="text"
                :loading="loadingdata"
              >
                <div class="d-flex justify-end align-center">
                  <v-switch
                    v-model="payableData.vat"
                    hide-details="auto"
                    :disabled="changeState"
                    :class="[!changeState ? changeState : '']"
                    class="input__field_style"
                  ></v-switch>
                </div>
              </v-skeleton-loader>
            </v-col>
            <hr class="hr-line" />
          </v-row>
          <v-row class="px-4 py-0 mb-mb-8 mx-sm-0">
            <v-col cols="4" class="pl-4 py-1 ma-0 d-flex align-center">
              <div class="">
                <span class="primary--text text-body-2 font-weight-bold"
                  >Due Date</span
                >
              </div>
            </v-col>
            <v-col cols="8" class="py-1 ma-0">
              <v-skeleton-loader
                width="100%"
                type="text"
                :loading="loadingdata"
              >
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="payableData.due_date"
                      hide-details="auto"
                      :disabled="changeState"
                      :background-color="backgroundColor"
                      solo
                      flat
                      dense
                      v-bind="attrs"
                      v-on="on"
                      :class="[!changeState ? changeState : '']"
                      reverse
                      class="input__field_style"
                      placeholder="Enter Due Date"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    @input="menu2 = false"
                    no-title
                    scrollable
                    v-model="payableData.due_date"
                    :min="minDate"
                  >
                  </v-date-picker>
                </v-menu>
              </v-skeleton-loader>
            </v-col>
            <hr class="hr-line" />
          </v-row>
          <v-row class="px-4 py-0 mx-sm-0">
            <v-col cols="4" class="pl-4 py-1 ma-0 d-flex align-center">
              <div class="">
                <span class="primary--text text-body-2 font-weight-bold"
                  >Currency</span
                >
              </div>
            </v-col>
            <v-col cols="8" class="py-1 ma-0">
              <v-skeleton-loader
                width="100%"
                type="text"
                :loading="loadingdata"
              >
                <v-select
                  :rules="rules.required"
                  v-model="payableData.currency"
                  hide-details="auto"
                  :disabled="changeState"
                  :background-color="backgroundColor"
                  solo
                  flat
                  :items="currencyItems"
                  dense
                  :class="[!changeState ? changeState : '']"
                  reverse
                  class="input__field_style"
                  placeholder="Set Currency"
                ></v-select>
              </v-skeleton-loader>
            </v-col>
            <hr class="hr-line" />
          </v-row>

          <div>
            <p
              class="py-5 mt-4 px-6 invoiceType"
              v-if="singleInvoice.invoicetype == 'vendor'"
            >
              Vendor Details
            </p>
            <p
              v-if="singleInvoice.invoicetype == 'customer'"
              class="py-5 mt-4 px-6 invoiceType"
            >
              Customer Details
            </p>

            <div>
              <v-row class="px-4 py-0 mx-sm-0">
                <v-col cols="4" class="pl-4 py-1 ma-0 d-flex align-center">
                  <div class="">
                    <span class="primary--text text-body-2 font-weight-bold"
                      >Name</span
                    >
                  </div>
                </v-col>
                <v-col cols="8" class="py-1 ma-0">
                  <v-skeleton-loader
                    width="100%"
                    type="text"
                    :loading="loadingdata"
                  >
                    <v-text-field
                      :rules="rules.required"
                      v-model="payableData.vendor.vendorname"
                      hide-details="auto"
                      disabled
                      background-color="transparent"
                      solo
                      flat
                      dense
                      reverse
                      class="input__field_style"
                      placeholder="Enter Name"
                    ></v-text-field>
                  </v-skeleton-loader>
                </v-col>
                <hr class="hr-line" />
              </v-row>
              <v-row class="px-4 py-0 mx-sm-0">
                <v-col cols="4" class="pl-4 py-1 ma-0 d-flex align-center">
                  <div class="">
                    <span class="primary--text text-body-2 font-weight-bold"
                      >Address</span
                    >
                  </div>
                </v-col>
                <v-col cols="8" class="py-1 ma-0">
                  <v-skeleton-loader
                    width="100%"
                    type="text"
                    :loading="loadingdata"
                  >
                    <v-text-field
                      :loading="loadingdata"
                      v-model="payableData.vendor.address"
                      hide-details="auto"
                      disabled
                      background-color="transparent"
                      solo
                      flat
                      dense
                      reverse
                      class="input__field_style"
                      placeholder="Enter Address"
                    ></v-text-field>
                  </v-skeleton-loader>
                </v-col>
                <hr class="hr-line" />
              </v-row>
              <v-row class="px-4 py-0 mx-sm-0">
                <v-col cols="4" class="pl-4 py-1 ma-0 d-flex align-center">
                  <div class="">
                    <span class="primary--text text-body-2 font-weight-bold"
                      >Tax Id.</span
                    >
                  </div>
                </v-col>
                <v-col cols="8" class="py-1 ma-0">
                  <v-skeleton-loader
                    width="100%"
                    type="text"
                    :loading="loadingdata"
                  >
                    <v-text-field
                      :loading="loadingdata"
                      v-model="payableData.vendor.taxid"
                      hide-details="auto"
                      disabled
                      background-color="transparent"
                      solo
                      flat
                      dense
                      reverse
                      class="input__field_style"
                      placeholder="Enter Tax Id."
                    ></v-text-field>
                  </v-skeleton-loader>
                </v-col>
                <hr class="hr-line" />
              </v-row>
              <v-row class="px-4 py-0 mx-sm-0">
                <v-col cols="4" class="pl-4 py-1 ma-0 d-flex align-center">
                  <div class="">
                    <span class="primary--text text-body-2 font-weight-bold"
                      >Email</span
                    >
                  </div>
                </v-col>
                <v-col cols="8" class="py-1 ma-0">
                  <v-skeleton-loader
                    width="100%"
                    type="text"
                    :loading="loadingdata"
                  >
                    <v-text-field
                      :loading="loadingdata"
                      v-model="payableData.vendor.email"
                      hide-details="auto"
                      disabled
                      background-color="transparent"
                      solo
                      flat
                      dense
                      reverse
                      class="input__field_style"
                      placeholder="Enter your Email"
                    ></v-text-field>
                  </v-skeleton-loader>
                </v-col>
                <hr class="hr-line" />
              </v-row>
              <v-row class="px-4 py-0 mx-sm-0">
                <v-col cols="4" class="pl-4 py-1 ma-0 d-flex align-center">
                  <div class="">
                    <span class="primary--text text-body-2 font-weight-bold"
                      >Phone</span
                    >
                  </div>
                </v-col>
                <v-col cols="8" class="py-1 ma-0">
                  <v-skeleton-loader
                    width="100%"
                    type="text"
                    :loading="loadingdata"
                  >
                    <v-text-field
                      :loading="loadingdata"
                      v-model="payableData.vendor.contact.phone"
                      hide-details="auto"
                      disabled
                      background-color="transparent"
                      solo
                      flat
                      dense
                      reverse
                      class="input__field_style"
                      placeholder="Enter Phone"
                    ></v-text-field>
                  </v-skeleton-loader>
                </v-col>
                <hr class="hr-line" />
              </v-row>
            </div>
          </div>
          <div class="pl-6 pr-4 pt-6">
            <p class="mb-0 pb-6 invoiceType">Invoice Items</p>

            <div
              v-for="(line, index) in payableData.lines"
              :key="`Input-${index}`"
            >
              <v-row>
                <v-col cols="12" sm="6" md="7" class="pr-md-0 pb-1 pb-md-4">
                  <v-text-field
                    ref="lineItemdescription"
                    v-model="line.description"
                    hide-details="auto"
                    :disabled="changeState"
                    :background-color="backgroundColor"
                    :rules="rules.required"
                    solo
                    flat
                    dense
                    placeholder="Item"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="5" class="pl-md-0 pt-1 pt-md-3">
                  <div class="d-flex d-md-flex align-center pl-1">
                    <v-btn
                      v-if="singleInvoice.lines[index].quantity > 0"
                      x-small
                      color="primary"
                      :disabled="changeState"
                      icon
                      @click="decreaseQty(index)"
                    >
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>

                    <p class="quantity mb-0 px-1">{{ line.quantity }}</p>
                    <v-btn
                      x-small
                      color="primary"
                      :disabled="changeState"
                      icon
                      @click="increaseQty(index)"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>

                    <v-text-field
                      ref="lineItemprice"
                      class="ml-1"
                      v-model.number="line.price"
                      hide-details="auto"
                      :disabled="changeState"
                      :background-color="backgroundColor"
                      :rules="rules.price"
                      solo
                      flat
                      dense
                      placeholder="Price"
                    >
                    </v-text-field>
                  </div>
                </v-col>
              </v-row>
              <hr class="hr-line" />
            </div>
            <div class="d-flex justify-space-between pt-1 pb-3">
              <p
                class="remove__text text-capitalize"
                text
                @click="removeInput(payableData.lines)"
                :disabled="changeState"
                v-show="payableData.lines && payableData.lines.length > 1"
              >
                - last item
              </p>
              <p></p>

              <p
                class="link__text text-right text-capitalize"
                text
                @click="addInput"
                :disabled="changeState"
              >
                + New Item
              </p>
            </div>
            <hr class="hr-line" />
          </div>
        </div>

        <div class="px-6" style="padding-bottom: 20px">
          <div class="d-flex align-center justify-center text-center pt-14">
            <v-btn
              :disabled="!changeState || isDisabled"
              @click="triggerModal"
              large
              elevation="10"
              :loading="isSending"
              color="primary"
              width="100%"
              height="50px"
            >
              <simple-line-icons
                style="width: 15.98px; height: 15.97px"
                class="m-0 text--white"
                icon="cursor"
                no-svg
              />
              <span
                class="pl-8 text-capitalize"
                v-if="singleInvoice.invoicetype == 'vendor'"
                >Approve for payment</span
              >
              <span class="pl-8 text-capitalize" v-else>Send Invoice</span>
            </v-btn>
          </div>
          <InvoiceBtn
            :isLoading="isLoading"
            :showReview="changeState"
            :isCustomer="singleInvoice.invoicetype == 'vendor' ? false : true"
            @downloadPDF="handlePDFDownload"
            @clickedSaved="changeFieldStatus"
            class="pt-8"
          />
        </div>
      </v-form>
    </div>
    <!-- add to payables modal -->
    <AddToPayModal ref="addtopayable" />
    <SendCustomerInvoice ref="SendInvoiceModal" />
  </div>
</template>

<script>
import SimpleLineIcons from "vue-simple-line";
import InvoiceBtn from "./InvoiceBtn.vue";
import { mapActions, mapGetters } from "vuex";
import AddToPayModal from "./overlays/AddToPayModal.vue";
import SendCustomerInvoice from "../pages/invoices/components/sendCustomerInvoice.vue";

export default {
  name: "BasicData",

  data() {
    return {
      isAnException: false,
      menu: false,
      menu2: false,
      loadingdata: false,
      isLoading: false,
      isSending: false,
      changeState: true,
      isSaved: false,
      saveData: true,
      vendorData: {
        name: "",
        address: "",
        taxId: "",
        email: "",
        phone: "",
      },
      customerData: {
        name: "",
        address: "",
        email: "",
        phone: "",
      },
      sendInvoiceData: {
        customer: {
          customername: "",
          address: "",
          taxid: "",
          email: "",
          contact: {
            phone: "",
          },
        },
      },
      payableData: {
        vendor: {
          vendorname: "",
          address: "",
          taxid: "",
          email: "",
          contact: {
            phone: "",
          },
        },
        invoicenumber: "",
        vat: "",
        currency: "",
        date: null,
        due_date: this.$moment().format("YYYY-MM-DD"),
        total: "",
        lines: [],
      },
      rules: {
        required: [(v) => !!v || "This field is required"],
        email: [
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
          (v) => !!v || "E-mail is required",
        ],
        phone: [
          (v) => !!v || "Phone is required",
          (v) => v.length >= 11 || "Phone must be at least 11 characters",
        ],
        amount: [
          (v) => !!v || "Amount is required",
          (v) => v > 0 || "Amount must be greater than 0",
        ],
        price: [
          (v) => !!v || "Price is required",
          (v) => v > 0 || "Price must be greater than 0",
        ],
      },
      currencyItems: ["NGN", "USD", "GBP", "EUR"],
      lines: [],
    };
  },
  components: {
    SimpleLineIcons,
    InvoiceBtn,
    AddToPayModal,
    SendCustomerInvoice,
  },

  methods: {
    ...mapActions({ showToast: "ui/showToast" }),
    async handlePDFDownload() {
      // console.log("handleDownloadPDF");
      // console.log(this.singleInvoice.hypn_id)
      this.isLoading = true;
      const payload = {
        id: this.singleInvoice?.hypn_id,
        send: false,
      };
      try {
        await this.$store
          .dispatch("contacts/sendCustomerInvoice", payload)
          .then((res) => {
            if (process.env.NODE_ENV === "development") {
              console.log(res);
            }
            this.showToast({
              sclass: "success",
              show: true,
              message: res.message || "Invoice generated successfully",
              timeout: 3000,
            });
            // open a new window tab with res.data.file as the url
            window.open(res.data.file, "_blank");
          });
      } catch (error) {
        console.log(error);
        this.showToast({
          sclass: "error",
          show: true,
          message: error.msg || "Error Generating Invoice",
          timeout: 3000,
        });
      } finally {
        this.isLoading = false;
      }
    },
    changeFieldStatus() {
      if (this.changeState) {
        this.changeState = false;
        this.saveData = true;
      } else {
        this.changeState = true;
        this.saveInvoice();
      }
      if (process.env.NODE_ENV === "development") {
        console.log(this.updateInvoicePayload);
      }
    },

    addInput() {
      this.payableData.lines.push({
        description: "",
        quantity: 1,
        price: 0,
      });
      console.log("hello");
    },
    removeInput(inputType) {
      if (inputType.length > 0) {
        inputType.pop(); // Remove the last item
      }
      const totalAmount = this.recalculateTotal();
      this.payableData.total = totalAmount.toString();
    },

    //increase quantity
    increaseQty(index) {
      this.payableData.lines[index].quantity++;

      const totalAmount = this.recalculateTotal();
      this.payableData.total = totalAmount.toString();
    },
    //decrease quantity
    decreaseQty(index) {
      if (this.payableData.lines[index].quantity > 1) {
        this.payableData.lines[index].quantity--;
      }
      const totalAmount = this.recalculateTotal();
      this.payableData.total = totalAmount.toString();
    },

    // recalculate the total amount...
    recalculateTotal() {
      let total = 0;
      this.payableData.lines.forEach((line) => {
        total += line.quantity * Math.abs(line.price);
      });

      return total;
    },

    //save and update invoice data
    saveInvoice() {
      try {
        this.$store
          .dispatch("invoices/updateInvoiceById", {
            id: this.$route.params.id,
            payload: this.updateInvoicePayload,
          })
          .then(() => {
            this.showToast({
              sclass: "success",
              show: true,
              message: "Invoice Saved Successfully",
              timeout: 3000,
            });
          });
      } catch (error) {
        this.showToast({
          sclass: "error",
          show: true,
          message: error.message || "Error Saving Invoice",
          timeout: 3000,
        });
      } finally {
        this.saveData = false;
        this.isSaved = true;
      }
    },

    triggerModal() {
      if (this.singleInvoice.invoicetype === "vendor") {
        const isInvoiceNumberValid =
          this.$refs.invoicenumber.$refs.input.validate();
        const isInvoiceTotalValid = this.$refs.invoicetotal.validate();
        const areLineItemsValid = this.payableData.lines.every((line) => {
          const isDescriptionValid =
            line.description && line.description.trim().length > 0;
          const isPriceValid = !isNaN(line.price) && parseFloat(line.price) > 0; // Checking for a positive price
          return isDescriptionValid && isPriceValid;
        });

        if (isInvoiceNumberValid && isInvoiceTotalValid && areLineItemsValid) {
          if (!this.isSaved) {
            this.saveInvoice();
          }
          this.changeFieldStatus();
          this.$refs.addtopayable.openDialog();
        } else {
          this.showToast({
            sclass: "error",
            show: true,
            message: "Please fill all necessary fields",
            timeout: 3000,
          });
        }
      } else {
        // Validation logic for other types of invoices
        const requiredFields = [
          "invoicenumber",
          "currency",
          "date",
          "due_date",
          "total",
        ];
        const areFieldsValid = requiredFields.every((field) => {
          return this.$refs[field] && this.$refs[field].validate();
        });

        if (areFieldsValid) {
          if (!this.isSaved) {
            this.saveInvoice();
          }
          this.$refs.SendInvoiceModal.show(true);
        } else {
          this.showToast({
            sclass: "error",
            show: true,
            message: "Please fill all required fields",
            timeout: 3000,
          });
        }
      }
    },

    // validation for only vendor invoice selected fields
    validateInvoiceData(invoiceData) {
      const isInvoiceNumberValid =
        invoiceData.invoicenumber &&
        invoiceData.invoicenumber.trim().length > 0;
      const isInvoiceTotalValid =
        !isNaN(invoiceData.total) && parseFloat(invoiceData.total) >= 0;
      const areLineItemsValid = invoiceData.lines.every((line) => {
        const isDescriptionValid =
          line.description && line.description.trim().length > 0;
        const isPriceValid = !isNaN(line.price) && parseFloat(line.price) > 0; // Checking for a positive price
        return isDescriptionValid && isPriceValid;
      });

      return isInvoiceNumberValid && isInvoiceTotalValid && areLineItemsValid;
    },

    // logic to just set the payable Data total amount on mount
    handleSingleInvoice() {
      this.loadingdata = false;
      console.log(this.singleInvoice.total);
      this.payableData.total = this.singleInvoice.total;
    },
  },

  computed: {
    ...mapGetters({
      singleInvoice: "invoices/getSingleInvoice",
    }),

    NumOfExceptions() {
      return Object.keys(this.singleInvoice.exception_fields).length;
    },
    exceptionTags() {
      const arrayValues = Object.values(this.singleInvoice.exception_fields);
      return arrayValues.map((item) => item.replaceAll("_", " "));
    },

    backgroundColor() {
      return this.changeState ? "transparent" : "#ffffff";
    },
    //format payload to update invoice
    updateInvoicePayload() {
      if (this.singleInvoice.invoicetype == "vendor") {
        const payload = {
          ...this.payableData,
          vendor: this.singleInvoice.vendor.id,
        };
        return payload;
      } else {
        return {
          customer: this.singleInvoice.customer.id,
          invoicenumber: this.payableData.invoicenumber,
          vat: this.payableData.vat,
          currency: this.payableData.currency,
          date: this.payableData.date,
          due_date: this.payableData.due_date,
          total: this.payableData.total,
          lines: this.payableData.lines,
        };
      }
    },

    formatedDate() {
      const date = new Date(this.singleInvoice.created_at);

      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();

      if (day < 10) {
        day = "0" + day;
      }
      if (month < 10) {
        month = "0" + month;
      }
      return day + "/" + month + "/" + year;
    },
    formatedDueDate() {
      if (this.singleInvoice.due_date) {
        return this.$moment(this.singleInvoice.due_date).format("DD/MM/YYYY");
      } else return this.$moment().format("DD/MM/YYYY");
    },

    isDisabled() {
      if (this.$route.query.exception) {
        return this.saveData;
      } else {
        //switch statement to check if invoice has been sent
        switch (this.singleInvoice.status) {
          case "sent":
            return true;
          case "paid":
            return true;
          case "overdue":
            return true;

          default:
            return false;
        }
      }
    },
    singleInvoiceArray() {
      return Object.entries(this.singleInvoice).map((e) => ({
        [e[0]]: e[1],
      }));
    },
    //get minimum date using moment
    minDate() {
      return this.$moment(this.singleInvoice.created_at).format("YYYY-MM-DD");
    },
    configSymbol() {
      return this.singleInvoice.currency ? this.singleInvoice.currency : "";
    },
  },

  mounted() {
    this.isAnException = this.$route.query.exception || false;
    this.loadingdata = true;
    setTimeout(
      () => {
        if (this.$route.query.review) {
          this.changeState = false;
        }
        this.loadingdata = false;
      },
      3000,
      this.singleInvoice
    );
    // Check if singleInvoice is already available in the store
    if (this.singleInvoice) {
      this.handleSingleInvoice();
    }
  },

  watch: {
    singleInvoice: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val != null) {
          if (val.invoicetype == "vendor") {
            this.payableData.vendor.vendorname = val.vendor?.vendorname;
            this.payableData.vendor.address = val.vendor?.address;
            this.payableData.vendor.taxid = val.vendor?.taxid;
            this.payableData.vendor.email = val.vendor?.email;
            this.payableData.vendor.contact.phone =
              val.vendor?.contact?.phone || "";
            this.payableData.invoicenumber = val.invoicenumber;
            this.payableData.vat = val.vat;
            this.payableData.currency = val.currency;
            this.payableData.date = this.$moment(val.created_at).format(
              "DD/MM/YYYY"
            );
            this.payableData.due_date = val.due_date;
            this.payableData.lines = val.lines;
          } else if (val.invoicetype == "customer") {
            if (val.customer) {
              this.payableData.vendor.vendorname =
                val.customer?.firstname + " " + val.customer?.lastname || "";
            }

            this.payableData.vendor.address =
              val.customer?.address?.address || "";
            this.payableData.vendor.taxid = val.customer?.tax_number || "";
            this.payableData.vendor.email = val.customer?.email || "";
            this.payableData.vendor.contact.phone =
              val.customer?.contact?.phone || "";
            this.payableData.invoicenumber = val.invoicenumber;
            this.payableData.vat = val.vat;
            this.payableData.currency = val.currency;
            this.payableData.date = this.$moment(val.created_at).format(
              "DD/MM/YYYY"
            );
            this.payableData.due_date = val.due_date || this.formatedDueDate;
            this.payableData.lines = val.lines;
          }
        }
      },
    },

    // singleInvoice(newValue) {
    //   if (newValue) {
    //     this.handleSingleInvoice();
    //   }
    // },

    "singleInvoice.status": {
      handler(val) {
        this.isDisabled = val === "sent";
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
.sub__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: rgba(25, 40, 61, 0.8);
}

.total__amount {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  line-height: 39px;
  color: #455160;
}

.amount_text_size {
  font-size: 1.9rem;
}

.invoiceType {
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #7f919b;
}

.hr-line {
  border-top: 1px dashed rgba(40, 28, 75, 0.233);
}

/* @media and (min-width: 900px) {
  
} */

.v-subheader {
  align-items: center;
  display: flex;
  height: 48px;
  font-size: 0.9rem !important;
  font-weight: 400;
  padding: 0 16px 0 16px;
}

.v-text-field {
  width: 260px;
}

.centered-input input {
  text-align: right;
}

i.sli-font {
  font-size: 16px;
  display: inline-block;
}

.v-btn--block {
  display: flex;
  flex: 1 0 auto;
  min-width: 380px !important;
  max-width: auto;
}

.changeState {
  background: #ffffff;
  border: 1px solid rgba(48, 31, 120, 0.07);
}

.input__field_style {
  text-align: right;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 15px;
  color: #7f919b;
}

.defaultState {
  background: transparent;
  border: none;
}

.v-divider--inset:not(.v-divider--vertical) {
  max-width: calc(100% - 38px) !important;
  margin-left: 18px;
}

.quantity {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 12px;
  color: #19283d;
  cursor: pointer;
}

.section__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: rgba(25, 40, 61, 0.8);
}

.link__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-decoration-line: underline;
  color: #5b67ba;
  cursor: pointer;
}

.remove__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-decoration-line: underline;
  color: red;
  cursor: pointer;
}

.v-text-field[data-v-12b9862d] {
  width: 294px !important;
}

/* media queries for large screens */

@media only screen and (min-width: 900px) {
  .invoiceType {
    font-size: 16px;
    line-height: 19px;
  }
}
</style>
