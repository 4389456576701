<template>
  <div>
    <v-dialog
      elevation="0"
      v-model="dialog"
      max-width="590"
      overlay-color="#19283d"
      overlay-opacity="0.282397"
    >
      <v-card
        max-width=""
        flat
        class="m-0"
        style="background: #f8f7f4; border-radius: 8px"
      >
        <v-card-title class="modalTitleCont">
          <!-- <img
            :src="require('@/assets/icons/task-square.png')"
            width="20"
            height="20"
          /> -->
          <span class="ml-5 modalTitle">Add To Payables</span>
          <v-spacer></v-spacer>
          <v-btn @click="closeFormDialog" icon color="primary">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <template>
          <v-tabs
            slider-size="4"
            background-color="#F6F3EE"
            v-model="tab"
            align-with-title
            style="border-bottom: 1px solid #d9dee1"
          >
            <v-tabs-slider color="primary"></v-tabs-slider>

            <v-tab> payable </v-tab>
          </v-tabs>
        </template>
        <v-tabs-items v-model="tab" style="background-color: #f8f7f4">
          <v-tab-item class="mt-10">
            <div class="d-flex justify-space-between mx-8">
              <div class="d-block">
                <p class="card__supertitle py-0 mb-1 px-2">invoice total</p>
                <p class="card__title pt-0 px-2">
                  {{ singleInvoice.total | currency }}
                </p>
              </div>
              <div class="d-block">
                <p class="card__supertitle py-0 mb-1">Invoice Number</p>
                <p class="invoiceNumTxt pr-2">
                  {{ singleInvoice.invoicenumber }}
                </p>
              </div>
            </div>
            <div class="mx-4 mx-md-10 mt-6">
              <v-form ref="form">
                <!-- <div
                  class="py-5 px-4 mt-2 mb-11"
                  style="position: relative; background: #f6f3ee"
                >
                  <v-row v-for="(fieldRow, index) in fieldRows" :key="index">
                    <v-col class="py-1" cols="12" md="5">
                      <v-text-field
                        hide-details="auto"
                        background-color="white"
                        label="Amount Due"
                        outlined
                        v-model.number="fieldRows[index].payableData.amount_due"
                        :rules="amountRule"
                      ></v-text-field>
                    </v-col>
                    <v-col class="py-0" cols="12" md="6">
                      <v-menu
                        ><template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            hide-details="auto"
                            background-color="white"
                            label="Due Date"
                            append-icon="mdi-calendar-blank-outline"
                            outlined
                            :rules="dateRule"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :value="fieldRows[index].payableData.due_date"
                          >
                          </v-text-field>
                        </template>
                        <v-date-picker
                          v-model="fieldRows[index].payableData.due_date"
                          no-title
                          scrollable
                          :min="minDate"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col
                      class="d-flex justify-center align-center"
                      cols="12"
                      md="1"
                    >
                      <v-btn icon @click="removeFieldRow(index)">
                        <v-icon
                          class="mx-7"
                          small
                          id="no-background-hover"
                          tag="button"
                          plain
                          color="error"
                        >
                          mdi-close
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-btn
                    color="#19283D"
                    fab
                    dark
                    x-small
                    absolute
                    bottom
                    right
                    @click="addFieldRow"
                  >
                    <v-icon x-small>mdi-plus</v-icon>
                  </v-btn>
                </div> -->

                <v-row>
                  <v-col class="py-0" cols="12" md="6">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="true"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      ><template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          hide-details="auto"
                          background-color="white"
                          label="Due Date"
                          append-icon="mdi-calendar-blank-outline"
                          outlined
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          v-model="singleInvoice.due_date"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="singleInvoice.due_date"
                        no-title
                        scrollable
                        :min="minDate"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col class="py-0" cols="12" md="6">
                    <v-text-field
                      hide-details="auto"
                      background-color="#ffffff"
                      label=""
                      placeholder="Amount due"
                      v-model.number="amount_due"
                      :rules="amountRule"
                      outlined
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="" cols="12">
                    <v-textarea
                      rows="4"
                      hide-details="auto"
                      background-color="white"
                      placeholder="Description"
                      outlined
                      :rules="descriptionRule"
                      v-model="description"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
                <template class="mt-6">
                  <v-card-actions class="d-flex mt-2 justify-end px-0">
                    <v-btn
                      dark
                      width="121"
                      height="48"
                      color="primary"
                      class="text-capitalize mt-8 mb-16"
                      :loading="isSending"
                      @click="sendToPayables"
                    >
                      <simple-line-icons
                        style="font-size: 14px"
                        class="m-0 text--white"
                        icon="arrow-right"
                        no-svg
                      />
                      <span
                        class="pl-4"
                        style="
                          font-family: Inter;
                          font-style: normal;
                          font-weight: 500;
                          font-size: 14px;
                          line-height: 17px;
                        "
                        >Add</span
                      >
                    </v-btn>
                  </v-card-actions>
                </template>
              </v-form>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  components: {},
  name: "Form",
  data() {
    return {
      show: false,
      dialog: false,
      isSending: false,
      date: new Date(),
      tab: 0,
      modal: false,
      menu: false,
      // fieldRows: [
      //   {
      //     //menu: false,
      //     payableData: {
      //       due_date: null,
      //       amount_due: "",
      //     },
      //   },
      // ],

      description: "",
      amount_due: "",
      amountRule: [
        (value) => !!value || "Amount due is Required.",
        (v) =>
          Number.isInteger(Number(v)) || "The value must be an integer number",
        (v) => v > 0 || "The value must be a positive number",
      ],
      descriptionRule: [
        (v) => !!v || "Please provide a description",
        (value) => (value || "").length >= 20 || "Min 20 characters",
      ],

      dateRule: [(v) => !!v || "Due Date is required"],
      minDate: new Date().toISOString().slice(0, 10),
    };
  },

  computed: {
    ...mapGetters({
      singleInvoice: "invoices/getSingleInvoice",
    }),

    formattedtotal: {
      get() {
        return this.singleInvoice.total.toString().replace(",", "");
      },
      set(value) {
        return value;
      },
    },
  },
  methods: {
    ...mapActions({ showToast: "ui/showToast" }),
    closeFormDialog() {
      this.dialog = false;
    },

    openDialog() {
      this.dialog = true;
    },
    // addFieldRow() {
    //   this.fieldRows.push({
    //     menu: false,
    //     payableData: {
    //       due_date: "",
    //       amount_due: "",
    //     },
    //   });
    // },
    // removeFieldRow(index) {
    //   if (this.fieldRows.length === 1) {
    //     return;
    //   }
    //   this.fieldRows.splice(index, 1);
    // },
    // async sendToPayables() {
    //   this.isSending = true;

    //   if (this.$refs.form.validate()) {
    //     await this.fieldRows.map((payable) => {
    //       this.initiateSendToPayable(payable);
    //     });
    //   }
    // },
    async sendToPayables() {
      const payload = {
        amount_due: this.amount_due.toString(),
        date_due: this.singleInvoice.due_date.toString().replaceAll("-", ","),
        document_id: this.singleInvoice.invoicenumber,
        description: this.description,
        payee: "",
        total: parseInt(this.formattedtotal),
        organization: this.singleInvoice.organization.id.toString(),
        currency: this.singleInvoice.currency,
      };

      if (this.$refs.form.validate()) {
        try {
          const response = await this.$store.dispatch(
            "payables/addToPayables",
            payload
          );
          if (process.env.NODE_ENV === "development") {
            console.log(JSON.stringify(response, null, 2));
          }
          this.showToast({
            sclass: "success",
            show: true,
            message: "Sent data to Payables succesfully",
            timeout: 3000,
          });
          this.isSending = false;
        } catch (error) {
          this.isSending = false;
          this.showToast({
            sclass: "error",
            show: true,
            message: error.msg,
            timeout: 3000,
          });
        }
      }
    },
  },
  mounted() {
    //
  },
};
</script>

<style scoped>
.v-application .elevation-6 {
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 3%), 0px 6px 10px 0px rgb(0 0 0 / 3%),
    0px 1px 18px 0px rgb(0 0 0 / 3%) !important;
}
.v-input .v-label {
  font-size: 12px !important;
}
.v-btn--fab.v-size--x-small {
  height: 24px !important;
  width: 24px !important;
}

.modalTitleCont {
  background: #ffffff;
  border-radius: 8px 8px 0px 0px;
  height: 54px;
}
.modalTitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #19283d;
}

.card__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  color: #19283d;
}
.card__supertitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  /* bodyTxt_old */

  color: #596a73;
}
.field__title {
  margin-bottom: 20px;
  margin-top: 30px;
  margin-left: 32px;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.45px;
  color: #7f919b;
}
.form__field {
  margin-top: 12px;
  margin-left: 32px;
  margin-right: 32px;
  margin-bottom: 30px;
  /* background: #ffffff; */
  /* border: 1px solid rgba(212, 216, 223, 0.377431); */
  border-radius: 3px;
}

.invoiceNumTxt {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  color: #596a73;
}

.btn-text {
  padding-left: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.636364px;
  color: #ffffff;
}
@media only screen and (min-width: 600px) {
  /* For tablets: */
  .form__field {
    margin-top: 12px;
    margin-left: 67px;
    margin-right: 67px;
    margin-bottom: 30px;
    /* background: #ffffff; */
    /* border: 1px solid rgba(212, 216, 223, 0.377431); */
    border-radius: 3px;
  }

  .field__title {
    margin-bottom: 20px;
    margin-top: 30px;
    margin-left: 67px;
    font-family: "Inter";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.45px;
    color: #7f919b;
  }
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .form__field {
    margin-top: 12px;
    margin-left: 67px;
    margin-right: 67px;
    margin-bottom: 30px;
    /* background: #ffffff; */
    /* border: 1px solid rgba(212, 216, 223, 0.377431); */
    border-radius: 3px;
  }
  .field__title {
    margin-bottom: 20px;
    margin-top: 30px;
    margin-left: 67px;
    font-family: "Inter";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.45px;
    color: #7f919b;
  }
}
</style>
